<template>
  <li class="mb-2">
    <!-- active state is managed by nuxt routing -->
    <nuxt-link
      v-if="navItem.type === 'nuxt'"
      :to="navItem.data.url"
      :data-cy="dataCyName([navItem.label, 'sidebar-link'])"
      class="sidebar-link d-block text-secondary text-decoration-none p-1 rounded bg-transparent transition-color hover:bg-black-transparent"
      active-class="bg-black-transparent cursor-default"
    >
      <fa-icon
        v-if="navItem.icon"
        class="sidebar-link__icon text-rhino me-2"
        :icon="navItem.icon"
        width="20"
      />
      {{ translateLabel(navItem) }}
    </nuxt-link>
    <a
      v-else
      :href="navItem.data.url"
      :data-cy="dataCyName([navItem.label, 'sidebar-link'])"
      class="sidebar-link d-block text-secondary text-decoration-none p-1 rounded bg-transparent transition-color hover:bg-black-transparent"
      :target="navItem.type === 'external' ? '_blank' : null"
      :rel="navItem.type === 'external' ? 'noopener' : null"
      :class="{ 'sidebar-link--active': isActive }"
    >
      <fa-icon
        v-if="navItem.icon"
        class="sidebar-link__icon text-rhino me-2"
        :icon="navItem.icon"
        width="20"
      />
      {{ translateLabel(navItem) }}
    </a>
  </li>
</template>

<script lang="ts">
import dataCyName from '~/helpers/cypress';
import { type SideNavigationItem, SideNavigationItemTypes } from '../../types';
import { useLabelTranslation } from '../features/utils';

export default defineComponent({
  name: 'SidebarLinkAnchor',
  props: {
    navItem: {
      type: Object as PropType<SideNavigationItem>,
      required: true,
    },
  },
  setup(_props) {
    const route = useRoute();
    const translateLabel = useLabelTranslation();

    const isActive = computed<boolean>(() => {
      if ([SideNavigationItemTypes.EXTERNAL].includes(_props.navItem.type)) {
        return false;
      }

      return route.path.endsWith(_props.navItem.data.url);
    });

    return {
      isActive,
      translateLabel,
      dataCyName,
    };
  },
});
</script>
