import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';

export enum SideNavigationItemTypes {
  LINK = 'link',
  EXTERNAL = 'external',
  NUXT = 'nuxt',
}

export interface AbstractSideNavigationItem {
  key: string;
  label: string;
  translate: boolean;
  data: {
    url?: string;
    onclick?: (...args: unknown[]) => unknown;
  };
}

export interface SideNavigationItem extends AbstractSideNavigationItem {
  icon: IconDefinition;
  type: SideNavigationItemTypes;
  isActive?: boolean;
}

export interface SideNavigationGroupItem {
  key: string;
  label: string;
  translate: boolean;
  items: Array<SideNavigationItem>;
}

export interface SideNavigation {
  uplink?: AbstractSideNavigationItem;
  items: Array<SideNavigationGroupItem>;
}

export interface UISideNavigationItem extends SideNavigationItem {
  isActive: boolean;
}

export default {};
